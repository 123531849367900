export const zipCodes = [
  {
    label: "Green",
    zipCodes: [
      80001, 80002, 80003, 80004, 80005, 80006, 80007, 80010, 80011, 80012,
      80013, 80014, 80015, 80016, 80017, 80018, 80019, 80021, 80022, 80024,
      80030, 80031, 80033, 80034, 80035, 80036, 80037, 80040, 80042, 80045,
      80102, 80107, 80108, 80110, 80111, 80112, 80113, 80120, 80121, 80122,
      80123, 80124, 80125, 80126, 80127, 80128, 80129, 80130, 80134, 80135,
      80137, 80138, 80150, 80151, 80155, 80160, 80161, 80162, 80165, 80166,
      80201, 80202, 80203, 80204, 80205, 80206, 80207, 80208, 80209, 80210,
      80211, 80212, 80214, 80215, 80216, 80217, 80218, 80219, 80220, 80221,
      80222, 80223, 80224, 80225, 80226, 80227, 80228, 80229, 80230, 80231,
      80232, 80235, 80236, 80237, 80238, 80239, 80243, 80244, 80246, 80247,
      80248, 80249, 80250, 80251, 80252, 80256, 80257, 80259, 80260, 80261,
      80262, 80263, 80264, 80265, 80266, 80271, 80273, 80274, 80281, 80290,
      80291, 80293, 80294, 80299, 80401, 80402, 80403, 80419, 80433, 80437,
      80439, 80453, 80454, 80465, 80640,
    ],
  },
  {
    label: "Yellow",
    zipCodes: [
      80104, 80106, 80109, 80116, 80117, 80118, 80131, 80163, 80020, 80023,
      80025, 80026, 80027, 80038, 80233, 80234, 80241, 80301, 80302, 80303,
      80304, 80305, 80306, 80307, 80308, 80309, 80310, 80314, 80422, 80455,
      80466, 80471, 80503, 80504, 80514, 80516, 80520, 80530, 80544, 80601,
      80602, 80603, 80614, 80621, 80642, 80643,
    ],
  },
  {
    label: "Red",
    zipCodes: [
      80501, 80502, 80513, 80534, 80537, 80538, 80539, 80542, 80543, 80550,
      80620, 80623, 80631, 80632, 80634, 80639, 80645, 80651, 80132, 80133,
      80817, 80829, 80831, 80840, 80863, 80901, 80902, 80903, 80904, 80905,
      80906, 80907, 80908, 80909, 80910, 80911, 80912, 80913, 80914, 80915,
      80916, 80917, 80918, 80919, 80920, 80921, 80922, 80923, 80924, 80925,
      80926, 80927, 80929, 80931, 80932, 80933, 80934, 80935, 80936, 80937,
      80938, 80939, 80941, 80942, 80944, 80946, 80947, 80949, 80950, 80951,
      80960, 80962, 80970, 80977, 80995, 80997,
    ],
  },
];

export const allDeliverableZipCodes = zipCodes
  .map((area) => area.zipCodes)
  .flat();
